<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-card :title="title">
            <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute"
                style="top:20px; left: 20px;" size="sm"><i class="fa fa-bars"></i></b-button>
            <b-collapse id="filterBox" class="my-2">
                <b-card>
                    <form @submit.prevent="searchItems()" id="search-item">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="title">نام</label>
                                    <b-form-input id="title" v-model="searchParams.name"></b-form-input>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <b-form-group label="شماره تلفن" label-for="telephone">
                                    <b-form-input id="telephone" v-model="searchParams.telephone"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group label="وضعیت" label-for="status">
                                    <b-form-select id="status" v-model="searchParams.status">
                                        <b-form-select-option value="pending">درحال انتظار</b-form-select-option>
                                        <b-form-select-option value="visited">دیده شده</b-form-select-option>
                                        <b-form-select-option value="ok">تایید شده</b-form-select-option>
                                        <b-form-select-option value="">همه</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
                            جو</button>
                    </form>
                </b-card>
            </b-collapse>
            <b-table responsive striped hover v-if="items.data" :fields="fields" :items="items.data.data">
                <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | persianDate }}
                </template>
                <template v-slot:cell(status)="data">
                    <div style="width: 80px;">
                        <p v-if="data.item.status == 'pending' " class="py-1 px-2 bg-danger text-center small rounded">
                            در حال انتظار</p>
                        <p v-else-if="data.item.status == 'visited'" class="p-1 bg-warning text-center small rounded">
                            دیده شده</p>
                        <p v-else-if="data.item.status == 'ok' " class="p-1 bg-success text-center small rounded">تایید
                            شده</p>
                    </div>
                </template>
                <template v-slot:cell(more)="data">
                    <b-button variant="info" @click="moreItem(data.index)"><i class="fas fa-eye"></i></b-button>
                </template>
                <template v-slot:cell(edit)="data">
                    <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
                </template>
                <template v-slot:cell(delete)="data">
                    <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i
                            class="fa fa-close"></i></button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>

        <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
        </pagination>
        <pagination :limit="2" v-else-if="items.data && search" :data="items.data"
            @pagination-change-page="searchItems">
        </pagination>

        <b-modal id="more-item" hide-footer size="lg" title="موارد بیشتر">
            <b-table-simple>
                <b-tbody>
                    <b-tr>
                        <b-td><b>نام :</b> {{ moreData.name }}</b-td>
                        <b-td colspan="2"><b> ایمیل:</b> {{ moreData.email }}</b-td>

                    </b-tr>
                    <b-tr>
                        <b-td><b>تلفن :</b> {{ moreData.telephone }}</b-td>
                        <b-td><b>وضعیت :</b>
                            <span v-if="moreData.status == 'pending'"> درحال انتظار</span>
                            <span v-else-if="moreData.status == 'visited'"> دیده شده</span>
                            <span v-else> تایید شده</span>
                        </b-td>
                        <b-td><b> تاریخ :</b> {{ moreData.created_at | persianDate}}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3"><b> متن :</b> {{ moreData.body }}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3"><b> توضیح :</b> {{ moreData.description }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-modal>
        <b-modal id="edit-modal" hide-footer title="ویرایش تماس با ما">
            <div v-if="edit.id">
                <form @submit.prevent="itemEdited" id="edit-item">
                    <b-form-group>
                        <label for="description">توضیح <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-textarea id="description" name="description" v-model="edit.description"
                            :disabled="disabled"></b-form-textarea>
                    </b-form-group>


                    <b-form-group>
                        <label for="status">وضعیت <i class="fas fa-asterisk text-danger"
                                style="font-size: 0.5rem;"></i></label>
                        <b-form-select id="status" name="status" v-model="edit.status" :disabled="disabled">
                            <b-form-select-option value="pending">درحال انتظار</b-form-select-option>
                            <b-form-select-option value="visited">دیده شده</b-form-select-option>
                            <b-form-select-option value="ok">تایید شده</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <input type="hidden" name="_method" value="PUT">
                    <div class="clearfix">
                        <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
                        <b-button class="float-left" variant="white" type="button"
                            @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import mixins from './mixins/mixins'

    export default {
        mixins: [mixins],
        data() {
            return {
                url: '/api/admin/contacts',
                fields: [{
                        key: "id",
                        label: "شناسه"
                    },
                    {
                        key: "name",
                        label: "نام"
                    },
                    {
                        key: "telephone",
                        label: "شماره تلفن"
                    },
                    {
                        key: "email",
                        label: "ایمیل"
                    },
                    {
                        key: "created_at",
                        label: "تاریخ ثبت"
                    },
                    {
                        key: "status",
                        label: "وضعیت"
                    },
                    {
                        key: "more",
                        label: "موارد بیشتر"
                    },
                    {
                        key: "edit",
                        label: "ویرایش"
                    },
                    {
                        key: "delete",
                        label: "حذف"
                    },
                ],
                title: 'تماس با ما',
                moreData: {

                }
            }
        },
        methods: {
            moreItem(index) {
                let item = this.items.data.data[index];
                this.moreData = item;
                this.$root.$emit('bv::show::modal', 'more-item')
            }
        },
        created() {
            this.loadItems()
            this.searchParams = {
                name: '',
                status: '',
                telephone: '',
            }
        }
    }
</script>